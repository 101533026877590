$theme-colors: (
  'primary': #67a78d,
  'secondary': #67a78d,
  'info': #308e70,
);

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';
@import 'variables';
@import 'forms';
@import 'buttons';

@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');

@font-face {
  font-family: 'Recoleta';
  src: url(./fonts/recoleta-bold-webfont.woff) format('woff'),
    url(./fonts/recoleta-bold-webfont.woff2) format('woff2');
  font-weight: 600;
  font-style: normal;
}

// @font-face {
//   font-family: 'recoletaregular';
//   src: url('recoleta-regular-webfont.woff2') format('woff2'),
//        url('recoleta-regular-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: 'Recoleta';
  src: local('Recoleta'), url(./fonts/Recoleta-Regular.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta';
  src: local('Recoleta'), url(./fonts/Recoleta-Light.woff2) format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta';
  src: local('Recoleta'), url(./fonts/Recoleta-Medium.woff2) format('woff2');
  font-weight: 500;
  font-style: normal;
}

// @font-face {
//   font-family: 'Recoleta';
//   src: local('Recoleta'), url(./fonts/Recoleta-SemiBold.woff2) format('woff2');
//   font-weight: 600;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Recoleta';
//   src: local('Recoleta'), url(./fonts/Recoleta-Bold.woff2) format('woff2');
//   font-weight: 700;
//   font-style: normal;
// }

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
  font-family: 'Cabin';
  font-weight: 400;
  font-size: 16px;
  background-color: #f4f0ed;
  color: #707070;
}

:root {
  color: #707070;
  box-sizing: border-box;
  font-size: 16px;
}

#root {
  min-height: 100%;
  width: 100%;

  > main {
    min-height: 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .row {
    margin: 0 !important;
  }
}

.form-label {
  font-size: 12px;
  color: #45b79f;
}

.jumbotron {
  background-color: #ffffff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Recoleta', serif;
  font-weight: 600;
  color: $heroGreen;
}

h1 {
  font-size: 2.625rem;
}
h2 {
  font-size: 2.375rem;
}
h3 {
  font-size: 1.75rem;
}
h4 {
  font-size: 1.375rem;
}
h5 {
  font-size: 1.25rem;
}
h6 {
  font-size: 1.125rem;
}

.white {
  color: #ffffff;
}

p {
  color: #707070;
  font-family: 'Cabin';
  font-size: 1rem;
}

input {
  font-family: 'Lato', 'Cabin', sans-serif;
}

#react-select-4-listbox,
#react-select-5-listbox,
#react-select-6-listbox {
  z-index: 9;
  position: absolute;
  background: white;
}

.list-group-flush > .list-group-item {
  border-width: 1px 0 1px;
}

.list-group-item.active {
  background-color: #e8e4e1;
}

.list-group-item {
  padding: 0.75rem 0.75rem;
}

.print-only {
  display: none;
}

@media print {
  body {
    background-color: #ffffff;
    -webkit-print-color-adjust: exact;
  }
  .no-print {
    display: none !important;
  }
  .print-only {
    display: block !important;
  }
  
  .navbar {
    display: block;
    padding: 5px 0 !important;

    & .row {
      & > div:first-child button,
      & > div:nth-child(2) {
        display: none;
      }
    }
  }  

  .container-table {
    min-width: 100% !important;
    
    & div:nth-of-type(8n) {
      break-after: always;
    }
    & div:nth-of-type(9n) {
      margin-top: 80px;
    }

    & > div > div p {
      line-height: 1;
    }
    & > div > div h4 {
      margin-bottom: 0;
    }
    & > div > div > div:nth-child(2) {
      margin-top: 5px;
    }
  }

  .diet {
    margin-top: 80px;
  }

  .page-br {
    page-break-before: always;
    padding-top: 80px;
  }  

  .image-print {
    margin-top: 100px;
  }

  .page-br-img {
    page-break-before: always;
    margin-top: 80px;
  }  

  .print-padding-top {
    padding-top: 100px;
  }
}

