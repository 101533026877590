.btn {
  padding: 13px 30px 13px 30px;
  background: #1AA88C;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  color: #ffffff;
  font-size: 1rem;
  line-height: 1;
  border: 0;
  text-transform: uppercase;

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    color: #ffffff;
  }
}

.btn-secondary {
  padding: 13px 30px 13px 30px;
  background: #ffffff;
  border: 2px solid #67a78d;
  border-radius: 5px;
  color: #67a78d;

  &:hover {
    color: #509a7c;
    background-color: #ffffff;
  }
}

.btn-stacked {
  padding: 13px 30px 13px 30px;
  background: #ffffff;
  border: 2px solid #67a78d;
  border-radius: 5px;

  &:hover {
    color: #509a7c;
    background-color: #ffffff;
  }
}

.btn-outline {
  padding: 13px 30px 13px 30px;
  background: transparent;
  border: 2px solid #ffffff;
  border-radius: 5px;
  color: #ffffff;

  &:hover {
    color: #509a7c;
    background-color: #ffffff;
  }
}

.btn-primary {
  padding: 13px 30px 13px 30px;
  background: #1AA88C;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  color: #ffffff;
  font-size: 1rem;
  line-height: 1;
  border: 0;
  text-transform: uppercase;

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    color: #ffffff;
  }
}

.btn-primary-outline {
  padding: 13px 30px 13px 30px;
  background: transparent;
  border: 2px solid #1AA88C;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  color: #1AA88C;
  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    color: #1AA88C;
  }
}

.btn-danger {
  padding: 13px 30px 13px 30px;
  background: #DC3444;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  color: #ffffff;
  font-size: 1rem;
  line-height: 1;
  border: 0;
  text-transform: uppercase;

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    color: #ffffff;
  }
}

