$primary    : #1AA88C;

$heroGreen  : #1AA88C;
$yellow     : #eed698;
$pink       : #deb4b6;
$blue       : #abdcd6;
$tan        : #f1e6e0;
$link       : #3886b7;
$error      : #af2e34;
$alert      : #3886b7;

$gray       : #707070;

$archiveGreen     : #1AA88C;
