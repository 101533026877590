.form {
  .invalid-feedback {
    display: block;
  }
}

.form-dark {
  .form-label {
    color: $white;
  }

  .invalid-feedback {
    color: $white;
  }
}

.form-light {
  color: $gray;

  .form-label {
    color: $gray;
  }
}

.datepicker-wrapper {
  width: 100%;
}
